<template>
  <div class="container mt-4">
    <b-row>
      <b-col class="d-flex align-items-center">
        <BackButton></BackButton>
        <h5 class="ml-2 mb-0">
          {{ invoiceDetails.page_title }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="canPushOrReject()">
        <b-button variant="danger"
                  class="mr-2"
                  @click="rejectInvoice">
          <feather type="slash"></feather>
          {{ translations.reject_invoice }}
        </b-button>
        <b-button variant="primary"
          @click="pushInvoiceToNetsuite">
          <feather type="arrow-up-circle"></feather>
          {{ translations.push_to_netsuite }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card class="border-0 w-100" :header="translations.details.invoice_detail" body-class="px-0" header-class="border-0">
          <b-col>
            <b-row cols="7" class="mb-5">
              <b-col>
                <b>{{ translations.details.invoice_id }}</b>
                <p>{{ invoiceDetails.id }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.external_id }}</b>
                <p>{{ invoiceDetails.external_id }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.billable_client_id }}</b>
                <p>{{ invoiceDetails.client_id }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.billable_client }}</b>
                <p>{{ invoiceDetails.client_name }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.start_date }}</b>
                <p>{{ invoiceDetails.start_date }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.end_date }}</b>
                <p>{{ invoiceDetails.end_date }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.status }}</b>
                <p><b-badge :variant="formatStatusLabel(invoiceDetails.status)">{{ invoiceDetails.status }}</b-badge></p>
              </b-col>
            </b-row>
            <b-row cols="7" class="mb-5">
              <b-col>
                <b>{{ translations.details.created_at }}</b>
                <p>{{ invoiceDetails.created_at }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.billing_type }}</b>
                <p>{{ invoiceDetails.billing_type }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.payer }}</b>
                <p>{{ invoiceDetails.payer_name }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.sword_provider }}</b>
                <p>{{ invoiceDetails.billing_provider_name }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.department }}</b>
                <p>{{ invoiceDetails.department }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.line_department }}</b>
                <p>{{ invoiceDetails.line_department }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.po_number }}</b>
                <p>{{ invoiceDetails.po_number }}</p>
              </b-col>
            </b-row>
            <b-row cols="7" class="mb-5">
              <b-col>
                <b>{{ translations.details.addressee }}</b>
                <p>{{ invoiceDetails.ship_to_adressee }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.address }}</b>
                <p>{{ invoiceDetails.ship_to_address }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.city }}</b>
                <p>{{ invoiceDetails.ship_to_city }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.state }}</b>
                <p>{{ invoiceDetails.ship_to_state }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.zip_code }}</b>
                <p>{{ invoiceDetails.ship_to_zip_code }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.country }}</b>
                <p>{{ invoiceDetails.ship_to_country }}</p>
              </b-col>
              <b-col/>
            </b-row>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <b-card class="border-0 w-100" header-class="border-0" body-class="custom-card-body">
      <b-card-header class="border-0 w-100">
        <b-row>
          <b-col class="d-flex align-items-center">
            <h5 class="ml-2 mb-0">
              {{ translations.details.clients.client_list }}
            </h5>
          </b-col>
          <b-button
            size="sm"
            class="btn-expand"
            @click="toggleClientList(clientExpanded)">
            <feather v-if="clientExpanded" type="chevron-up"/>
            <feather v-else type="chevron-down"/>
          </b-button>
        </b-row>
      </b-card-header>
      <financial-list
        v-if="clientExpanded"
        v-model="currentPageClients"
        :header-data="CLIENT_TABLE_HEADERS"
        :data="invoiceClients"
        :disabled=true
        @page-changed="formatInvoiceClients"
        :total="totalClients"
        :items-per-page="totalClients"
        :no-items-message="translations.details.clients.empty_invoice_list"/>
    </b-card>
    <b-card class="border-0 w-100" :header="translations.details.statements.statements_list" header-class="border-0">
      <financial-list
        v-model="currentPageStatements"
        :header-data="STATEMENT_TABLE_HEADERS"
        :data="invoiceStatements"
        :disabled="loadingPageStatements"
        :items-per-page="itemsPerPageStatements"
        @page-changed="loadInvoiceStatements"
        :total="totalStatements"
        :no-items-message="translations.details.statements.empty_invoice_list"/>
    </b-card>
    <b-card class="border-0 w-100" :header="translations.details.invoice_summary.invoice_summary_list" header-class="border-0">
      <financial-list
        v-model="currentPageSummary"
        :header-data="INVOICE_SUMMARY_TABLE_HEADERS"
        :data="invoiceSummary"
        :disabled="loadingPageSummary"
        :items-per-page="itemsPerPageSummary"
        @page-changed="loadInvoiceSummary"
        :total="totalSummary"
        :no-items-message="translations.details.invoice_summary.empty_invoice_list"/>
    </b-card>
  </div>
</template>

<script>
import FinancialList, { associateHeaderDataItem, generateCustomComponentColumn } from '@/components/Financial/FinancialList.vue';
import BackButton from '@/components/BackButton.vue';
import translations from '@/translations';
import { INVOICE_STATUS } from '@/constants/finance';
import { formatDate, formatDatetime, formatValue } from '@/helpers/finance';

export default {
  components: {
    BackButton,
    FinancialList,
  },
  name: 'FinanceInvoicesDetails',
  created() {
    this.CLIENT_TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.details.clients.id, 'id'),
      associateHeaderDataItem(this.translations.details.clients.name, 'name'),
    ];
    this.STATEMENT_TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.details.statements.id, 'id'),
      associateHeaderDataItem(this.translations.details.statements.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.details.statements.start_date, 'start_date'),
      associateHeaderDataItem(this.translations.details.statements.end_date, 'end_date'),
      associateHeaderDataItem(this.translations.details.statements.balance, 'balance'),
      associateHeaderDataItem(this.translations.details.statements.created_at, 'created_at'),
    ];
    this.INVOICE_SUMMARY_TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.details.invoice_summary.client_id, 'client_id'),
      associateHeaderDataItem(this.translations.details.invoice_summary.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.details.invoice_summary.transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translations.details.invoice_summary.quantity, 'num_of_transactions'),
      associateHeaderDataItem(this.translations.details.invoice_summary.unitary_value, 'value'),
      associateHeaderDataItem(this.translations.details.invoice_summary.unit, 'unit'),
      associateHeaderDataItem(this.translations.details.invoice_summary.line_description, 'observations'),
    ];
    this.invoiceID = this.$route.params.invoiceID;
  },
  data() {
    return {
      translations: translations.finance.invoices,
      invoiceDetails: {},
      invoiceSummary: [],
      invoiceClients: [],
      invoiceStatements: [],
      statementIds: [],
      itemsPerPageStatements: 5,
      currentPageStatements: 1,
      totalStatements: 0,
      loadingPageStatements: true,
      itemsPerPageSummary: 10,
      currentPageSummary: 1,
      totalSummary: 0,
      loadingPageSummary: true,
      currentPageClients: 1,
      totalClients: 0,
      clientExpanded: false,
      statementsFirstLoad: true,
    };
  },
  async beforeMount() {
    await this.loadInvoice();
    await this.loadInvoiceSummary();
    await this.loadInvoiceStatements();
  },
  methods: {
    async pushInvoiceToNetsuite() {
      try {
        const { invoiceID } = this;
        await this.$store.dispatch('Financial/pushInvoiceToNetsuite', { invoiceID });
        this.$noty.success(this.translations.successfully_pushed_to_netsuite);
        await this.loadInvoice();
      } catch (err) {
        let errMsg = this.translations.errors.pushing_to_netsuite;
        if (err.response?.data?.error) {
          let errDetail = this.translations.errors[err.response.data.error];
          if (!errDetail) {
            errDetail = err.response.data.error;
          }
          errMsg = `${errMsg}: ${errDetail}`;
        }
        this.$noty.error(errMsg);
      }
    },
    async rejectInvoice() {
      try {
        const { invoiceID } = this;
        await this.$store.dispatch('Financial/rejectInvoice', { invoiceID });
        this.$noty.success(this.translations.successfully_rejected_invoice);
        await this.loadInvoice();
      } catch (err) {
        const errDetail = this.translations.errors[err.response?.data?.error] || err.response?.data?.error;
        const errMsg = errDetail
          ? `${this.translations.errors.reject_invoice}: ${errDetail}`
          : this.translations.errors.reject_invoice;
        this.$noty.error(errMsg);
      }
    },
    async loadInvoice() {
      const { invoiceID } = this;
      const invoice = await this.$store.dispatch('Financial/getInvoice', { invoiceID });

      if (invoice) {
        this.formatInvoiceDetails(invoice);
        this.formatInvoiceClients(invoice);
      }
    },
    async loadInvoiceSummary() {
      this.loadingPageSummary = true;
      const { invoiceID } = this;

      const params = {
        offset: (this.currentPageSummary - 1) * this.itemsPerPageSummary,
        limit: this.itemsPerPageSummary,
      };
      const invoiceItems = await this.$store.dispatch('Financial/getInvoiceItems', { invoiceID, params });
      if (invoiceItems) {
        this.invoiceSummary = invoiceItems.items.map(item => (
          {
            ...item,
            value: formatValue(item.value),
          }
        ));
        this.totalSummary = invoiceItems.total;
      }
      this.loadingPageSummary = false;
    },
    formatInvoiceDetails(invoice) {
      this.invoiceDetails = {
        id: invoice.id || '-',
        external_id: invoice.external_id || '-',
        client_id: invoice.client_id || '-',
        client_name: invoice.client_name || '-',
        start_date: formatDate(invoice.start_date),
        end_date: formatDate(invoice.end_date),
        status: invoice.status || '-',
        created_at: formatDatetime(invoice.created_at),
        billing_type: invoice.billing_type || '-',
        payer_name: invoice.payer.payer_name || '-',
        billing_provider_name: invoice.billing_provider.name || '-',
        department: invoice.department || '-',
        line_department: invoice.line_department || '-',
        po_number: invoice.po_number || '-',
        ship_to_adressee: invoice.ship_to_adressee || '-',
        ship_to_address: invoice.ship_to_address || '-',
        ship_to_city: invoice.ship_to_city || '-',
        ship_to_state: invoice.ship_to_state || '-',
        ship_to_zip_code: invoice.ship_to_zip_code || '-',
        ship_to_country: invoice.ship_to_country || '-',
        page_title: `Invoice Details${invoice.client_name ? ` - ${invoice.client_name}` : ''}`,
      };
    },
    async loadInvoiceStatements() {
      this.loadingPageStatements = true;
      const { invoiceID } = this;

      const filters = {
        selected_ids: (this.statementIds).join(','),
        offset: (this.currentPageStatements - 1) * this.itemsPerPageStatements,
        limit: this.itemsPerPageStatements,
        order_by: 'id',
        invoice_id: invoiceID,
      };

      const { data: { data } } = await this.$store.dispatch('Financial/getStatements', filters);

      this.invoiceStatements = [];
      this.totalStatements = data.total;
      data.statements.forEach(statement => {
        const propsData = {
          to: `/finance/statements/${statement.id}`,
        };
        const id = generateCustomComponentColumn('BLink', propsData, statement.id);

        this.invoiceStatements.push({
          id,
          client_name: statement.client_name,
          start_date: formatDate(statement.start_date),
          end_date: formatDate(statement.end_date),
          balance: formatValue(statement.net_balance),
          created_at: formatDatetime(statement.created_at),
        });
      });

      this.loadingPageStatements = false;
    },
    formatInvoiceClients(invoice) {
      this.invoiceClients = [];
      invoice.merged_clients.forEach(client => {
        const propsData = {
          to: `/onboarding/clients/${client.client_id}/billing`,
        };
        const id = generateCustomComponentColumn('BLink', propsData, client.client_id);

        this.invoiceClients.push({
          id,
          name: client.client_name,
        });
      });
      this.totalClients = invoice.merged_clients.length;
    },
    formatStatusLabel(status) {
      if (status?.toLowerCase() === INVOICE_STATUS.SUBMITTED) {
        return 'success';
      }
      if (status?.toLowerCase() === INVOICE_STATUS.AWAITING_PROCESSING || status?.toLowerCase() === INVOICE_STATUS.PROCESSING) {
        return 'primary';
      }
      if (status?.toLowerCase() === INVOICE_STATUS.FAILED) {
        return 'danger';
      }
      return 'secondary';
    },
    toggleClientList(clientExpanded) {
      this.clientExpanded = !clientExpanded;
    },
    canPushOrReject() {
      return (this.$isSudo && this.invoiceDetails.status === INVOICE_STATUS.PROCESSING)
        || this.invoiceDetails.status === INVOICE_STATUS.PENDING
        || this.invoiceDetails.status === INVOICE_STATUS.FAILED;
    },
  },
};
</script>

<style>
.custom-card-body {
  padding-left: 0;
  padding-right: 0;
}

.btn-expand {
  min-width: 50px;
  max-width: 50px;
}

.btn-expand-col {
  width: 50px;
}

.expanded-row {
  background-color: rgba(0, 0, 0, .04);
}
</style>
